'use strict';

const main = {
  /**
   * Main components/functions list
   */
  init: () => {
    main.navBackdrop.init();
    // main.ga.init();
    // main.cookies.init();
    main.toc();
  },
  navBackdrop: {
    init: () => {
      const header = document.querySelector('body > header');
      const el = header.querySelector('#navbarNav');

      const tmpl = document.createElement('div');
      tmpl.classList.add('d-lg-none', 'modal-backdrop', 'fade', 'z-n1');
      tmpl.style.setProperty('--bs-backdrop-opacity', '.5');
      tmpl.style.height = header.getBoundingClientRect().height;
      tmpl.style.top = 'auto';

      el.addEventListener('show.bs.collapse', () => {
        const backdrop = tmpl.cloneNode();
        header.append(backdrop);

        const show = () => {
          setTimeout(() => backdrop.classList.add('show'), 1);
          document.body.style.overflow = 'hidden';
        };
        const hide = () => {
          bootstrap.Collapse.getInstance(el).hide();
          backdrop.remove();
          document.body.style.overflow = '';
        };

        const resizeHandler = () => {
          if (window.innerWidth > 992) {
            hide();
          }
        };
        const handler = () => {
          hide();
          window.removeEventListener('resize', resizeHandler);
        };

        backdrop.addEventListener('click', handler);
        el.addEventListener('hide.bs.collapse', handler, {once: true});

        show();
        window.addEventListener('resize', resizeHandler);
      });
    },
  },
  cookies: {
    cookieName: 'cookiesAccepted',
    callbacks: [],
    init: () => {
      switch (main.cookies.get()) {
        case 'false':
          break;
        case 'true':
          main.cookies.callbacks.forEach(callback => callback());
          break;
        default:
          main.cookies.showBanner();
          break;
      }
    },
    get: () => {
      const name = main.cookies.cookieName + '=';
      const decodedCookie = decodeURIComponent(document.cookie);

      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    set: (value, exdays) => {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = 'expires=' + d.toUTCString();

      let cookie = `${main.cookies.cookieName}=${value}; ${expires};path=/; SameSite=Strict`;
      if (location.protocol === 'https:') {
        cookie += `; Secure`;
      }
      document.cookie = cookie;
    },
    showBanner: () => {
      const banner = document.querySelector('#cookie-banner');
      banner.classList.remove('d-none');
    },
    setPreference: pref => {
      const banner = document.querySelector('#cookie-banner');
      banner.classList.add('d-none');
      main.cookies.set(pref, 30);
    },
    accept: () => {
      main.cookies.setPreference('true');
      main.cookies.callbacks.forEach(callback => callback());
    },
    decline: () => {
      main.cookies.setPreference('false');
    },
  },
  ga: {
    src: 'https://www.googletagmanager.com/gtag/js?id=G-0000000000',
    init: () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() { dataLayer.push(arguments); }

      gtag('consent', 'default', {
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 500,
      });
      gtag('js', new Date());
      gtag('config', 'G-0000000000');

      main.cookies.callbacks.push(main.ga.callback);
    },
    callback: () => {
      gtag('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'granted'
      });

      const script = document.createElement('script');
      script.src = main.ga.src;
      script.setAttribute('async', 'async');
      script.setAttribute('crossorigin', 'anonymous');
      document.head.appendChild(script);
    },
  },
  url: {
    // https://github.com/django/django/blob/main/django/contrib/admin/static/admin/js/urlify.js

    DOWNCODE_MAP: {
      // Latin Map
      'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE',
      'Ç': 'C', 'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I',
      'Î': 'I', 'Ï': 'I', 'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O',
      'Õ': 'O', 'Ö': 'O', 'Ő': 'O', 'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U',
      'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH', 'Ÿ': 'Y', 'ß': 'ss', 'à': 'a',
      'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
      'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i',
      'ï': 'i', 'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o',
      'ö': 'o', 'ő': 'o', 'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',
      'ű': 'u', 'ý': 'y', 'þ': 'th', 'ÿ': 'y'
    },
    Downcoder: {
      Initialize: () => {
        if (main.url.Downcoder.regex) { return; }
        main.url.Downcoder.regex = new RegExp(Object.keys(main.url.DOWNCODE_MAP).join('|'), 'g');
      }
    },
    downcode: s => {
      main.url.Downcoder.Initialize();
      return s.replace(main.url.Downcoder.regex, m => main.url.DOWNCODE_MAP[m]);
    },
    urlify: s => {
      return main.url.downcode(s)
        .trim()
        .toLowerCase()
        .replace(/[^-\w\s]/g, '')
        .replace(/[-\s]+/g, '-')
        .replace(/-+$/g, '');
    },
  },
  toc: () => {
    const content = document.querySelector('[data-toc]');
    if (!content) { return; }

    const toc = document.querySelector(content.dataset.toc);
    const ol = document.createElement('ol');

    const outline = content.querySelectorAll('h2');
    outline.forEach(h => {
      h.id = main.url.urlify(h.textContent);

      const li = document.createElement('li');
      const a = document.createElement('a');

      a.textContent = h.textContent;
      a.href = `#${h.id}`;

      li.appendChild(a);
      ol.appendChild(li);
    });

    toc.appendChild(ol);
  },
};

main.init();

window.main = main;
